import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import votante from '../fetchs/votante';


export class LinkUnico extends React.Component {
    state = {
        msgErro: '',
        msgType: null,
        ehLinkUnico: false
    }

    componentDidMount = async () => {
        const searchParams = new URLSearchParams(this.props.location.search);
        const evento = this.props.evento;
        const votanteId = searchParams.get('votanteId');
        const auth = searchParams.get('auth');

        if (votanteId && auth) {
            this.setState({ ehLinkUnico: true });
        } else {
            return;
        }

        if (!votanteId || !auth) {
            this.setState({msgType:'info', msgErro: 'Para logar no evento, entre em contato com os mesários' });
            return;
        }

        const autentica = await votante.fetchAutentica(evento, {
            votanteId: parseInt(votanteId),
            auth: auth
        });

        switch (autentica.status) {
            case 404:
            case 401:
                this.setState({msgType:'warning', msgErro: autentica.data.mensagem });
                break;
            case 200:
                this.props.onLogin(autentica.data.nome, autentica.data.tokenTemporario);
                break;
            default:
                console.error(autentica.data);
                alert('Ops.. Erro ao efetuar a autenticação, tente novamente mais tarde');
                break;
        }
    }

    render() {
        if (!this.state.ehLinkUnico) {
            return this.props.children;
        }

        if (this.state.msgErro) {
            return (
                <div className="w-25 mx-auto text-center mt-3">
                    <Alert variant={this.state.msgType}>{this.state.msgErro}</Alert>
                </div>
            );
        }

        return (
            <div className="text-center mt-3">
                <Spinner variant="primary2" animation="border" role="status">
                    <span className="sr-only">Autenticando</span>
                </Spinner>
                <div className="text-muted">Autenticando...</div>
            </div>
        );
    }
}


const mapStateToProps = store => ({
    evento: store.eventoState.evento.item
});




export default connect(mapStateToProps)(withRouter(LinkUnico)); 