import ResponseModel from "./response-model";
import dataURLtoBlob from 'dataurl-to-blob';
import mime from 'mime-types';
import { getStateUsuario } from "./meusDados";

async function post(idEvento, params) {
  const fotoFile = params.fotoFile;

  let postReq = null;
  if (fotoFile) {
    delete params.fotoFile;
    postReq = await postComFoto(idEvento, params, fotoFile);
  } else {
    postReq = await postSemFoto(idEvento, params);
  }


  switch (postReq.status) {
    case 201:
      return { success: true, status: postReq.status, data: await postReq.json() };
    case 400:
    case 401:
    case 404:
    case 422:
      return { success: false, status: postReq.status, data: await postReq.json() };
    default:
      alert('Ops... tente novamente mais tarde');
      console.error(await postReq.text());
      break;
  }
  return null;

}

async function postSemFoto(idEvento, params) {

  const url = new URL(process.env.REACT_APP_URL_API + '/eventos/' + idEvento + '/votante');


  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  });


}


async function postComFoto(idEvento, params, fotoFile) {

  fotoFile = dataURLtoBlob(fotoFile);
  
  for (const key of Object.keys(params)) {
    if (params[key] === null) params[key] = '';
  }

  const url = new URL(process.env.REACT_APP_URL_API + '/eventos/' + idEvento + '/votante');

  const formData = new FormData();
  formData.append('fotoFile', fotoFile, 'foto.' + mime.extension(fotoFile.type));
  for (const key in params) {
    if (key === 'demaisDados') {
      for (const keyDemaisDados in params[key]) {
        formData.append('demaisDados[' + keyDemaisDados + ']', params[key][keyDemaisDados]);
      }
    } else {
      formData.append(key, params[key]);
    }
  }
  


  return fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: formData
  });

}


async function fetchEnviarTokenAprovacao(evento, votanteId) {

  const aprovacaoFetch = await fetch(process.env.REACT_APP_URL_API + '/eventos/' + evento.id + '/votante/' + votanteId + '/token-aprovar', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  });

  const response = new ResponseModel();
  return await response.init(aprovacaoFetch);
}


async function fetchAprovar(evento, data) {
  const aprovacaoFetch = await fetch(process.env.REACT_APP_URL_API + '/eventos/' + evento.id + '/votante/' + data.votanteId + '/token-aprovar', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data),
  });


  const response = new ResponseModel();
  return await response.init(aprovacaoFetch);
}


async function fetchAutentica(evento, data) {
  const autentica = await fetch(process.env.REACT_APP_URL_API + '/eventos/' + evento.id + '/autenticar-votante', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },

    body: JSON.stringify(data),
  });

  const response = new ResponseModel();
  return await response.init(autentica);
}


async function fetchToken(evento, {integracaoId}) {
  const url = new URL(process.env.REACT_APP_URL_API + '/eventos/' + evento.id
  + '/token-votante');
  if (integracaoId) {
    url.search = new URLSearchParams({integracaoId});
  }

  const usuario = getStateUsuario();

  const fet = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + usuario.token
    }
  });

  const response = new ResponseModel();
  return await response.init(fet);

}

async function fetchQuestionario(evento, data) {

  const url = new URL(process.env.REACT_APP_URL_API + '/eventos/' + evento.id
    + '/autenticar-votante/questionario');
  url.search = new URLSearchParams(data);



  const fetchRes = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  });


  const response = new ResponseModel();
  return await response.init(fetchRes);

}



const votante = { post, fetchEnviarTokenAprovacao, fetchAprovar, fetchAutentica, fetchQuestionario, fetchToken };

export default votante;