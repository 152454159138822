import React from 'react';
import moment from 'moment';
import Cpf from './form-elements/Cpf';
import DataNascimento from './form-elements/DataNascimento';


class CpfDataNascimento extends React.Component {
  cpf = null;
  dataNascimento = null;

  state = {
    form: {cpf: '', dataNascimento: ''}
  }

  handleChange = async (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;

    await this.setState({form: {...this.state.form, [fieldName]: fleldVal}})

    let dataNascimento = null;
    if (moment(this.state.form.dataNascimento,'DD/MM/YYYY',true).isValid()) {
      dataNascimento = moment(this.state.form.dataNascimento,'DD/MM/YYYY').format('YYYY-MM-DD');
    }

    this.props.handleFormChange(
      { valid:this.valid() },
      {
        cpf: this.state.form.cpf && this.state.form.cpf.substr(0,3)+this.state.form.cpf.substr(4,3)+this.state.form.cpf.substr(8,3)+this.state.form.cpf.substr(12,2),
        dataNascimento: dataNascimento
      }
    );
  }

  valid = () => {
    return (this.cpf.valid() && this.dataNascimento.valid());
  }

  render() {
    return (
      <>
        <Cpf value={this.state.form.cpf} onChange={this.handleChange} ref={(ref)=> this.cpf = ref} required/>
        <DataNascimento value={this.state.form.dataNascimento} onChange={this.handleChange} ref={(ref)=> this.dataNascimento = ref} required />
      </>
    );
  }
}

export default CpfDataNascimento;
